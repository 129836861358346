<script
    lang="ts"
    setup
>
    type Props = {
        row?: boolean
    }

    const props = withDefaults(defineProps<Props>(), {
        row: false,
    })

    const containerClass = computed<string>(() => {
        let classes = 'relative overflow-y-auto flex w-full h-full bg-white'

        if (!props.row) {
            classes += ' flex-col'
        }

        return classes
    })
</script>

<template>
    <div :class="containerClass">
        <slot />
    </div>
</template>
